import api from "@/api/v24/api";

const apiAdmin = {
  /**
   *
   * @param departmentId Workaround for missing API feature: Filter on
   * department. Set to falsy for no filter
   * @returns {Promise}
   */
  getUsers(departmentId) {
    return new Promise(resolve => {
      api.execute("HentBrukere", null, "Brukere").then(users => {
        let filteredUsers = [];
        if (!departmentId) {
          filteredUsers = users;
        } else {
          filteredUsers = users.filter(user => {
            return user.Avdelinger.some(usrDep => {
              return usrDep.AvdelingId === departmentId;
            });
          });
        }
        resolve(filteredUsers);
      });
    });
  },

  getUser(id) {
    const params = { BrukerId: id };
    return new Promise(resolve => {
      api.execute("HentBruker", params, "Brukere").then(users => {
        resolve(users[0]);
      });
    });
  },

  setUserRoles(userId, roles) {
    const params = {
      Roller: [],
      BrukerId: userId
    };
    roles.forEach(role => {
      params.Roller.push({ RolleId: role });
    });
    return api.execute("SettBrukerRoller", params);
  },

  setUserDepartments(userId, departments) {
    const params = {
      Avdelinger: [],
      BrukerId: userId
    };
    departments.forEach(dep => {
      params.Avdelinger.push({ AvdelingId: dep });
    });
    return api.execute("SettBrukerAvdelinger", params);
  },

  createUser(user) {
    const params = {
      Bruker: user
    };
    return api.execute("NyBruker", params, null, true, true);
  },
  /**
   * @param {Object} user As used by V24 API:
   * {
    Navn: "",
    Epost: "",
    Mobilnr: "",
    Avdeling: "",
    Administrator: false,
    Sperret: false,
    FagEtat: "",
    KID: "",
    AcceptGDPR: false,
    Simulering: false,
    Tilgang: {
      VarslingSmsPremium: false,
      VarslingLokasjon: false,
      Maler: false,
      VarslingTime: false,
      VarslingSaab: false,
      VarslingGruppe: false,
      VA: false,
      Hendelse: false,
      Grupper: false,
      VarslingBefolkning: false,
      VarslingKommune: false,
      Saab: false
    },
    BrukerId: ""
  };
   */
  changeUser(user) {
    const changeFields = createUserFields(user);
    return changeUser(user.BrukerId, changeFields);
  },

  deactivateUser(user) {
    return setUserActiveState(user, true);
  },

  reactivateUser(user) {
    return setUserActiveState(user, false);
  }
};

const createUserFields = user => {
  return [
    {
      Verdi: user.Navn,
      Felt: "navn"
    },
    {
      Verdi: user.Epost,
      Felt: "epost"
    },
    {
      Verdi: user.Mobilnr,
      Felt: "mobilnr"
    },
    {
      Verdi: user.HovedAvdeling,
      Felt: "avdeling"
    },
    {
      Verdi: user.AnsattId,
      Felt: "ansattid"
    },
    {
      Verdi: toDbFormat(user.Administrator),
      Felt: "administrator"
    },
    {
      Verdi: toDbFormat(user.Sperret),
      Felt: "sperret"
    },
    {
      Verdi: toDbFormat(user.Tilgang.Maler),
      Felt: "tilgang_mal"
    },
    {
      Verdi: toDbFormat(user.Tilgang.VarslingGruppe),
      Felt: "tilgang_gruppe_varsling"
    },
    {
      Verdi: toDbFormat(user.Tilgang.Grupper),
      Felt: "tilgang_gruppe"
    }
  ];
};

const setUserActiveState = (user, state) => {
  const changeFields = [{ Verdi: toDbFormat(state), Felt: "Sperret" }];
  return changeUser(user.BrukerId, changeFields);
};

const toDbFormat = state => {
  return state ? "T" : "F";
};

const changeUser = (userId, changeFields) => {
  const params = {
    BrukerId: userId,
    Felter: changeFields
  };
  return api.execute("EndreBruker", params, null, null, true);
};

export default apiAdmin;
