<template>
  <div>
    <LoadingSpinner v-if="loading" />
    <v-card v-else>
      <v-card-title>
        {{ newUser ? $t("letsGetStarted") : $t("resetPassword") }}
      </v-card-title>
      <v-card-text>
        <PasswordCreator :init-email="initEmail" v-on:success="success">
          <template v-slot:enterEmailText>
            {{ $t("resetPassordInfo") }}
          </template>
        </PasswordCreator>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="cancel" v-if="!hideCancelBtn">
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import PasswordCreator from "@/components/auth/PasswordCreator";
import user from "@/util/user";
import LoadingSpinner from "@/components/misc/LoadingSpinner.vue";

export default {
  name: "NewPasswordRequest",
  components: { LoadingSpinner, PasswordCreator },
  props: {
    initEmail: {
      type: String,
      default: ""
    },
    newUser: {
      type: Boolean,
      default: false
    },
    hideCancelBtn: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    loading: true
  }),

  methods: {
    cancel() {
      this.$emit("cancel");
    },
    success(credentials) {
      this.$emit("success", credentials);
    }
  },

  created() {
    // Log out user if already logged in. May happen when this component is used
    // for new user registration and the person allrady has one account and
    // is looged in with it. Rare case, but it can happen.
    if (user.getSessionId()) {
      user.logOut(true).then(() => {
        window.location.reload();
      });
    } else {
      this.loading = false;
    }
  }
};
</script>
