import i18n from "../i18n";
import Email from "./Email";
import stringUtil from "@/util/stringUtil";
import config from "@/config/config";

const formValidationRules = {
  requiredRule: [v => (!!v && !!v.trim()) || i18n.t("mandatoryField")],
  groupShortnameRules: [
    v => {
      if (!v) {
        return true;
      }

      if (stringUtil.hasWhitespace(v)) {
        return i18n.t("noWhitespace");
      }

      if (!/^[a-zA-Z0-9]+$/.test(v)) {
        return i18n.t("onlyLettersAndNumbers");
      }

      const max = 16;
      if (v.length > max) {
        return i18n.t("maxXCharacters", { x: max });
      }
      return true;
    }
  ],
  phoneRules: [
    v => {
      if (!v) {
        return true;
      }
      if (v.length < config.phoneLengthLimit.min) {
        return i18n.t("invalidPhoneNumber");
      }
      if (!/^\d+$/.test(v)) {
        return i18n.t("phoneOnlyNumbers");
      } else if (v.length > config.phoneLengthLimit.max) {
        return i18n.t("tooManyDigits");
      }
      return true;
    }
  ],
  requiredEmailRules: [
    v => {
      const email = new Email(v);
      return email.isValid() || i18n.t("invalidEmail");
    }
  ],
  emailRules: [
    v => {
      if (!v) {
        return true;
      }
      const email = new Email(v);
      return email.isValid() || i18n.t("invalidEmail");
    }
  ],
  passwordRules: [
    v => {
      if (!v || v.length < 8) {
        return i18n.t("passMinRule");
      }
      if (v.length > 32) {
        return i18n.t("passMaxRule");
      }
      const hasLowerCase = stringUtil.hasLowerCase(v);
      const hasUpperCase = stringUtil.hasUpperCase(v);
      const hasNumber = stringUtil.hasNumber(v);
      if (!hasLowerCase || !hasUpperCase || !hasNumber) {
        return i18n.t("passGeneralRules");
      }
      return true;
    }
  ],
  urlRules: [
    v => {
      if (!v) {
        return true;
      }
      const isHttp = v.substr(0, 7) === "http://";
      const isHttps = v.substr(0, 8) === "https://";
      const isDoubleSlash = v.substr(0, 2) === "//";
      if (!isHttp && !isHttps && !isDoubleSlash) {
        return i18n.t("invalidLink");
      }
      return true;
    }
  ],
  ignoreWhitespace: [v => (!v && !!v.trim()) || i18n.t("mandatoryField")]
};

export default formValidationRules;
