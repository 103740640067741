import api from "./api";

const apiDepartment = {
  getAll() {
    return api.execute("HentAvdelinger", null, "Avdelinger");
  },

  get(id) {
    // API does not have HentAvdeling, so fetch all and then find the right one
    return new Promise(resolve => {
      this.getAll().then(allDepartments => {
        resolve(allDepartments.find(dep => dep.AvdelingId === id));
      });
    });
  },

  delete(id) {
    return new Promise((resolve, reject) => {
      const params = { AvdelingId: id };
      api
        .execute("SletteAvdeling", params, null, true, true)
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  create(name, smsSender = "") {
    const params = { Avdeling: { Navn: name, SmsAvsender: smsSender } };
    return api.execute("NyAvdeling", params);
  },

  edit(id, name, smsSender = "") {
    const params = {
      Avdeling: {
        AvdelingId: id,
        Navn: name,
        SmsAvsender: smsSender
      }
    };
    return api.execute("EndreAvdeling", params);
  },

  departmentExits(departmentId) {
    return new Promise(resolve => {
      this.getAll().then(allDepartments => {
        resolve(allDepartments.some(dep => dep.AvdelingId === departmentId));
      });
    });
  }
};

export default apiDepartment;
