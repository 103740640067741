import api from "./api";
import configVoice from "@/config/configVoice";

const apiVoice = {
  voices: null,
  getVoices() {
    return new Promise(function(resolve) {
      if (apiVoice.voices) {
        resolve(apiVoice.voices);
      } else {
        api.execute("GetVoices", null, "Voices").then(voices => {
          apiVoice.voices = sort(findSupportedVoices(voices));
          resolve(apiVoice.voices);
        });
      }
    });
  },
  sendSingle(recipient, text, voicePerson) {
    return new Promise((resolve, reject) => {
      configVoice.getDefaultCallerId().then(callerId => {
        const params = {
          BatchId: "",
          Script: {
            Voice: voicePerson,
            Content: text
          },
          Messages: [
            {
              CallerId: callerId,
              Recipient: recipient
            }
          ]
        };

        api.execute("SendVoice", params).then(res => {
          if (res.Status === "1 messages queued for sending") {
            resolve();
          } else {
            reject();
          }
        });
      });
    });
  }
};

const findSupportedVoices = voices => {
  return voices.filter(voice =>
    configVoice.supportedVoiceLanguages.some(
      suppLang => voice.LanguageCode === suppLang.code
    )
  );
};

const sort = voices => {
  return voices.sort((a, b) => a.LanguageCode.localeCompare(b.LanguageCode));
};

export default apiVoice;
