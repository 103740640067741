const mapAlertStatus = {
  active: "A",
  completed: "H",
  planned: "P",
  stopped: "S",
  deleted: "D"
};

const alertReceiverStatus = {
  /**
   * @name translate
   *
   * Translates alert receiver API status text to user language
   * @param alertStatus Status of the **alert**
   * @param simMode Set to true if alert is simulated
   * @param receiverStatus Receiver status as received from API
   * @param i18n the i18n object. This is passed as an argument instead of
   * beeing imported here so that user language change will be reactive
   * @returns {String}
   */
  translate(alertStatus, simMode, receiverStatus, i18n) {
    const statusTranslation = {
      Bekreftet: i18n.t("confirmed"),
      Mottatt: i18n.t("received"),
      Ubesvart: i18n.t("unans"),
      Duplikat: i18n.t("dupe"),
      Sendt: i18n.t("sent"),
      "Ikke sendt": i18n.t("notSent"),
      Active: i18n.t("active"),
      Reservert: i18n.t("reserved"),
      Avvist: i18n.t("rejected")
    };
    if (receiverStatus === "Ikke sendt" && !simMode) {
      if (alertStatus === mapAlertStatus.active) {
        return i18n.t("inQueue");
      }
      if (alertStatus === mapAlertStatus.planned) {
        return i18n.t("waiting");
      }
    }
    const translatedStatus = statusTranslation[receiverStatus];
    if (!translatedStatus) {
      return receiverStatus;
    } else {
      return translatedStatus;
    }
  }
};

export default alertReceiverStatus;
