/**
 * Wrapper for localStorage that can easily be mocked
 */
const storage = {
  setLocalItem(key, item) {
    localStorage.setItem(key, item);
  },
  getLocalItem(key) {
    // Don't remove "window" from window.localStorage even though it's not
    // neccessary when running in the browser. We need it for our stubs in
    // unit tests
    return window.localStorage.getItem(key);
  },
  setSessionItem(key, item) {
    window.sessionStorage.setItem(key, item);
  },
  getSessionItem(key) {
    return window.sessionStorage.getItem(key);
  }
};

export default storage;
