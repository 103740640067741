import apiSession from "../api/v24/api.session";
import apiUser from "../api/v24/api.user";
import store from "../store/index";
import config from "../config/config";
import storage from "@/util/storage";
import router from "@/router";
import apiDepartment from "@/api/v24/api.department";
import number from "@/util/numberUtil";

const user = {
  keySessionId: "s",
  keyLocale: "locale",
  keyIsV24User: "isV24User",
  keyIsSsoMsUser: "isSsoMsUser",
  keySsoMsLastUsedCode: "ssoMsLastUsedCode",
  keyDepartment: "selectedDepartment",
  keyTheme: "theme",
  keyAlertLogTab: "alertLogTab",
  profile: null,

  getSessionId() {
    return getSessionId();
  },

  setSessionId(id) {
    return setSessionId(id);
  },

  getProfile(forceServerFetch = false) {
    return new Promise(resolve => {
      if (user.profile && !forceServerFetch) {
        resolve(user.profile);
      } else {
        apiUser.getProfile().then(profile => {
          user.profile = profile;
          resolve(user.profile);
        });
      }
    });
  },

  validateDepartment(targetDepartment) {
    return new Promise(resolve => {
      this.getProfile().then(usrProfile => {
        if (usrProfile.Bruker.Admin) {
          resolve(true);
          return;
        }
        this.getDepartments().then(usrInfo => {
          if (!usrInfo.departments.length) {
            resolve(true);
            return;
          }
          const res = usrInfo.departments.some(
            usrDep => usrDep === targetDepartment
          );
          resolve(res);
        });
      });
    });
  },

  getDepartments() {
    return new Promise(resolve => {
      this.getProfile().then(profile => {
        let departments = [];
        const mainDepartment = profile.Bruker.Avdeling;
        if (mainDepartment) {
          departments.push(mainDepartment);
        }
        departments = departments.concat(
          ...profile.Bruker.Avdelinger.map(dep => dep.AvdelingId)
        );
        resolve({
          isAdmin: profile.Bruker.Admin,
          departments: departments
        });
      });
    });
  },

  changeProfile(name, email, mobile) {
    return new Promise(resolve => {
      apiUser.changeProfile(name, email, mobile).then(() => {
        // Update Vuex state
        store.dispatch("updateUserProfile", true).then(() => {
          resolve();
        });
      });
    });
  },

  canEditGroups() {
    return new Promise(resolve => {
      this.getProfile().then(profile => {
        resolve(
          profile.Bruker._permissions.manageContacts || profile.Bruker.Admin
        );
      });
    });
  },

  goToStartPage() {
    router.push({ path: "/alert" });
  },

  setSelectedDepartment(department) {
    // Save department to session storage.
    // Could we use Vuex for this? Yes, but it quickly gets complicated
    // when everthing connected to department, alert templates, contacts etc,
    // must be updated. Will the current view be correct...? I think it
    // may intruduce som strange user state bugs that will be hard to
    // reproduce and fix. Safeast thing to do is to reload the app. So then
    // we need session storage so that the users choice survives the reload
    // Also, we use **session** over local storage so that we don't come in
    // a situation where the user have two tabs open and then changes department
    // in one of them, leaving the other tab with an outdated state
    storage.setSessionItem(this.keyDepartment, department);
    window.location.reload();
  },

  getSelectedDepartment() {
    return storage.getSessionItem(this.keyDepartment) || "";
  },

  /**
   * Returns any keyword of type 0, that GroupAlert uses. If there are more then one keyword,
   * the first one will be resolved. If no keyword is found, undefined will be resolved.
   */
  getSmsCodeword() {
    return new Promise(resolve => {
      this.getProfile().then(profile => {
        const keyword = profile.Kodeord.find(kodewordProps => {
          return kodewordProps.KeywordType === 0;
        });
        resolve(keyword);
      });
    });
  },

  /**
   * Get the department to use when saving an item, contact, group etc...
   * The users selected department can be "" (empty string to indicate to the
   * API that we want all items connected to the users departments), but we
   * cannot save items with this department, since the user will not be able
   * to see the item in the list. So we use the users main department instead
   */
  getDepartmentForItemSave() {
    return new Promise(resolve => {
      const selectedDepartment = this.getSelectedDepartment();

      if (selectedDepartment) {
        // Check if the selected department exists just to be sure. In
        // theory it can be deleted by an admin since since last data fetch
        apiDepartment.departmentExits(selectedDepartment).then(exists => {
          if (exists) {
            // Use selected department
            console.log("Using selected department");
            resolve(selectedDepartment);
          } else {
            this.getProfile().then(profile => {
              // Department does not exits! Fallback to user main department
              console.log(
                "Defaulting to user main department, since sel. dep. does not exist"
              );
              const fallbackDep = profile.Bruker.Avdeling;

              // Update storage
              storage.setSessionItem(this.keyDepartment, fallbackDep);

              // Resolve
              resolve(fallbackDep);
            });
          }
        });
      } else {
        // No selected department
        this.getProfile().then(profile => {
          if (profile.Bruker.Admin) {
            // Admin can save items with blank department
            console.log("Using blank department. Ok for admin");
            resolve("");
            return;
          }
          // Fallback to user main department
          console.log("Defaulting to user main department");
          resolve(profile.Bruker.Avdeling);
        });
      }
    });
  },

  setAlertLogTab(tabId) {
    storage.setLocalItem(this.keyAlertLogTab, tabId);
  },

  getAlertLogTab() {
    // Convert to int since localStorage will always store values as strings
    return parseInt(storage.getLocalItem(this.keyAlertLogTab), 10) || 0;
  },

  setIsV24User(sessionId, simMode) {
    localStorage.setItem(user.keyIsV24User, "yes");
    store.commit("setAlertSimMode", simMode);
    return setSessionId(sessionId);
  },

  getIsV24User() {
    return getIsV24User();
  },

  setLasedUsedTheme(theme) {
    localStorage.setItem(user.keyTheme, theme);
  },

  getLasedUsedTheme() {
    return localStorage.getItem(user.keyTheme);
  },

  // SsoMs = single signon user using Microsoft Azure AD
  setIsSsoMsUser(sessionId, code) {
    localStorage.setItem(user.keySsoMsLastUsedCode, code);
    localStorage.setItem(user.keyIsSsoMsUser, "yes");
    return setSessionId(sessionId);
  },

  getSsoMsLastUsedCode() {
    return localStorage.getItem(user.keySsoMsLastUsedCode);
  },

  getIsSsoMsUser() {
    return getIsSsoMsUser();
  },

  setLocale(locale) {
    storage.setLocalItem(this.keyLocale, locale);
  },

  isLoggedIn() {
    const sessionId = getSessionId();
    return sessionId && sessionId.length > 0;
  },

  logIn(email, password, silent) {
    return new Promise((resolve, reject) => {
      let res = apiSession.login(email, password, silent);
      res.then(res => {
        if (res.SessionId === "") {
          // Empty SessionId means 2FA auth is required for this user
          resolve(true);
        } else if (!res.SessionId) {
          reject(getSessionErrorMessage());
        } else {
          setSessionId(res.SessionId).then(() => {
            resolve(false);
          });
        }
      });
      res.catch(err => {
        reject(err);
      });
    });
  },

  logInPin(email, password, pin) {
    return new Promise((resolve, reject) => {
      apiSession
        .loginPin(email, password, pin)
        .then(res => {
          if (!res.SessionId) {
            reject(getSessionErrorMessage());
            return;
          }
          setSessionId(res.SessionId).then(() => {
            resolve();
          });
        })
        .catch(err => {
          if (err.response.status === 401) {
            reject("Validation failed. Wrong PIN?");
          }
        });
    });
  },

  logOut(noRedirect = false) {
    return new Promise(resolve => {
      apiSession.logout(getSessionId()).then(() => {
        clientLogout(noRedirect);
        resolve();
      });
    });
  },

  logOutClient() {
    clientLogout();
  }
};

const getSessionErrorMessage = () => {
  return "Failed to establish session";
};

const setSessionId = id => {
  return new Promise(resolve => {
    localStorage.setItem(user.keySessionId, id);
    // Set session duration
    store.dispatch("updateUserProfile").then(userProfile => {
      const customerNum = userProfile.Kundenr;
      const sessionDuration = config.getDefaultSessionDuration(customerNum);
      apiSession.setSessionDuration(id, sessionDuration).then(() => {
        resolve();
      });
    });
  });
};

const getSessionId = () => {
  return window.localStorage.getItem(user.keySessionId);
};

const getIsV24User = () => {
  const isV24User = window.localStorage.getItem(user.keyIsV24User);
  return isV24User && isV24User === "yes";
};

const getIsSsoMsUser = () => {
  const isSsoMsUser = window.localStorage.getItem(user.keyIsSsoMsUser);
  return isSsoMsUser && isSsoMsUser === "yes";
};

const clientLogout = (noRedirect = false) => {
  const isV24User = getIsV24User();
  const isSsoMsUser = getIsSsoMsUser();
  let target = "/";
  const localStorageExceptions = [
    user.keyAlertLogTab,
    user.keyLocale,
    user.keyTheme
  ];
  for (let [key, value] of Object.entries(localStorage)) {
    // Don't remove these keys
    if (localStorageExceptions.includes(key)) {
      continue;
    }
    window.localStorage.removeItem(key);
  }
  for (let [key, value] of Object.entries(sessionStorage)) {
    window.sessionStorage.removeItem(key);
  }
  if (isV24User) {
    target = config.urlV24Logout;
  } else if (isSsoMsUser) {
    target = "/sso-ms-loggedout";
  }
  if (!noRedirect) {
    // Hard reload to get latest version and clear Vuex state
    window.location.href = target;
  }
};

export default user;
