import user from "../util/user";
import configServer from "@/config/configServer";
import userLocale from "@/util/userLocale";

const config = {
  appName: "GroupAlert",
  serverName: configServer.name,
  urlApp: configServer.url,
  urlV24Api: configServer.apiUrl,
  urlV24AudioApi: configServer.apiAudioUrl,
  urlV24ApiSso: configServer.apiSsoUrl,
  urlV24MainMenu: configServer.v24Url + "varslingsmeny.php",
  urlV24Logout: configServer.v24Url + "logout.php",
  defaultEmailSenderAddr: "noreply@groupalert.net",
  emailAttachmentMbimit: 11,
  supportedEmailFileTypes:
    ".mp3,.wav,.aif,.gsm,.txt,.doc,.docx,.pdf,.xls,.xlsx,.pps,.ppt,.pptx,.gif,.jpg,.jpeg,.png,.bmp",
  supportedAudioFileTypes: ".mp3,.wav,.aif,.gsm",
  alertContactsPerGroupLimit: 1500,
  uploadContactsLimit: 25000,
  alertContactsLimit: 50000,
  alertVoiceContactsLimit: 1000,
  dynamicNameTags: ["name", "navn", "namn"],
  phoneLengthLimit: {
    min: 6,
    max: 16
  },
  dialogWith: {
    small: 450,
    medium: 550,
    large: 800,
    xLarge: 1200
  },
  chartColors: {
    strongGreen: "rgb(10,132,41)",
    green: "rgb(82,187,109)",
    yellow: "rgb(199,255,115)",
    red: "rgb(255,98,91)",
    grey: "rgb(201, 203, 207)",
    white: "rgb(255, 255, 255)",
    blue: "rgb(90,183,231)",
    orange: "rgb(248,163,91)"
  },
  massDeleteLimit: {
    contacts: 1000,
    members: 1000,
    groups: 100
  },
  responseFeedbackVisitor: false,
  newUserVisitor: false,
  defaultTblRowsPerPage: 10,
  fixedTblItemsPerPageOptions: [10, 30, 50, 100],
  foundEmailContactInfo:
    "Hvordan vi fant din e-postadresse:\n" +
    "Din e-postadresse er registrert i Statens register over digital " +
    "kontaktinformasjon og reservasjon. Du kan lese mer om registeret hos " +
    "Digitaliseringsdirektoratet på https://norge.no/oppdater-kontaktinformasjon",
  get onboardingUrl() {
    return configServer.url + "new-user";
  },
  get responseUrl() {
    return configServer.url + "r?i=[v24ref]";
  },
  getAudioPlaybackUrl(fileId, fileClass = "A") {
    const sid = user.getSessionId();
    return (
      configServer.apiAudioUrl +
      fileId +
      "?SessionId=" +
      sid +
      "&class=" +
      fileClass
    );
  },
  getCallerId() {
    return new Promise(resolve => {
      user.getProfile().then(usrProfile => {
        if (usrProfile.Landskode === 45) {
          resolve("+4552512580");
        } else {
          resolve(configServer.callerId);
        }
      });
    });
  },
  getDefaultSessionDuration(custNum) {
    if (custNum === 32353) {
      // Borregaard session never expires
      return -1;
    }
    // Default one week
    return 60 * 24 * 7;
  },
  getDefaultLanguage() {
    let locale = userLocale.get();
    if (!locale) {
      locale = window.navigator.language || window.navigator.userLanguage;
    }

    // For our UI we are only interessted in the language, not the
    // region for the language.
    // More info here:
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#locale_identification_and_negotiation
    const localeIdentifiers = locale.split("-");
    return localeIdentifiers[0];
  },
  getVuetifyLocale(locale) {
    locale = locale ? locale : this.getDefaultLanguage();

    // Vuetify does not use offical web language codes.
    // Must map exceptions to codes used by Vuetify
    if (locale === "nn" || locale === "nb") {
      return "no";
    } else if (locale === "se") {
      return "sv";
    }
    return locale;
  },
  getLocaleProperties() {
    const locale = this.getDefaultLanguage();
    const nynorsk = locale === "nn";
    const bokmaal = locale === "nb";
    const norwegian = nynorsk || bokmaal;
    const danish = locale === "da";
    const swedish = locale === "se";
    let tagName = "[name]";
    if (bokmaal || danish) {
      tagName = "[navn]";
    } else if (nynorsk || swedish) {
      tagName = "[namn]";
    }
    return {
      csvSeparator: norwegian ? ";" : ",",
      tagName: tagName
    };
  }
};

export default config;
