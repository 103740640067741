<template>
  <v-menu right bottom>
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on"><v-icon>mdi-earth</v-icon></v-btn>
    </template>

    <v-list>
      <v-list-item-group color="primary">
        <v-list-item @click="switchTo('en')">
          <v-list-item-content>
            English
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="switchTo('nb')">
          <v-list-item-content>
            Norsk bokmål
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="switchTo('nn')">
          <v-list-item-content>
            Norsk nynorsk
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="switchTo('da')">
          <v-list-item-content>
            Dansk
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="switchTo('se')">
          <v-list-item-content>
            Svenska
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import i18n from "../../i18n";
import user from "../../util/user";
import config from "../../config/config";
import browser from "@/util/browser";
import store from "@/store";

export default {
  name: "LocaleSwitcher",
  methods: {
    switchTo(locale) {
      i18n.locale = locale;
      user.setLocale(locale);
      this.$vuetify.lang.current = config.getVuetifyLocale();
      store.commit("setTagNameBasedOnLocale");
      browser.setTabTitle(this.$t("groupAlert"));
    }
  }
};
</script>
