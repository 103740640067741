<template>
  <v-app>
    <MainNavigation v-if="navigationVisible" />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import LandingPage from "./views/LandingPage";
import user from "./util/user";
import MainNavigation from "./components/navigation/MainNavigation";
import config from "./config/config";
import browser from "@/util/browser";
import department from "@/util/department";

export default {
  name: "App",

  components: {
    MainNavigation,
    LandingPage
  },

  computed: {
    navigationVisible() {
      return (
        this.userIsLoggedIn &&
        !config.responseFeedbackVisitor &&
        !config.newUserVisitor
      );
    }
  },
  data: () => ({
    userIsLoggedIn: false
  }),
  created() {
    this.userIsLoggedIn = user.isLoggedIn();
    browser.setTabTitle(this.$t("groupAlert"));
    const lastUsedTheme = user.getLasedUsedTheme();
    if (lastUsedTheme) {
      this.$vuetify.theme.dark = lastUsedTheme === "dark";
    }
    if (this.userIsLoggedIn) {
      validateSelectedDepartment();
    }
  },
  updated() {
    this.userIsLoggedIn = user.isLoggedIn();
  }
};

const validateSelectedDepartment = () => {
  const selectedDep = user.getSelectedDepartment();

  user.getDepartments().then(usrDepInfo => {
    if (usrDepInfo.isAdmin) {
      // Handle admin user. Is selected department still available?
      department.getAll().then(departments => {
        const isAvail = departments.some(d => d.AvdelingId === selectedDep);
        if (!isAvail) {
          setNoDepartment();
        }
      });
    } else {
      if (!selectedDep) {
        // No department stored in session. This is okay, but not for users with
        // one departemnt, they should have it selected
        if (usrDepInfo.departments.length === 1) {
          user.setSelectedDepartment(usrDepInfo.departments[0]);
        }
        return;
      }
      // Handle non-admin with none or restricted set of departments
      // Is stored selected department is still available?
      if (usrDepInfo.departments.length) {
        const isAvail = usrDepInfo.departments.some(d => d === selectedDep);
        if (!isAvail) {
          user.setSelectedDepartment(usrDepInfo.departments[0]);
        }
      } else {
        // No departments tied to user any more. Clear selected department
        setNoDepartment();
        return;
      }
    }
  });
};

const setNoDepartment = () => {
  // If no department is alraedy set in sessionStorage, do nothing. Or we will
  // end up in a loop
  if (!user.getSelectedDepartment()) {
    return;
  }
  user.setSelectedDepartment("");
};
</script>
