<template>
  <v-snackbar v-model="snackbar.visible" top absolute>
    {{ snackbar.text }}
    <template v-if="snackbar.navButton" v-slot:action="{ attrs }">
      <v-btn
          outlined
          v-bind="attrs"
          @click="navigate"
      >
        {{ snackbar.navButton.text }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import store from "../../store";
import router from "../../router";

export default {
  name: "AppSnackbar",

  computed: {
    snackbar() {
      return store.state.appSnackbar
    }
  },

  methods: {
    navigate() {
      router.push(store.state.appSnackbar.navButton.path);
    }
  }
};
</script>
