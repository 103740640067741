<template>
  <div>
    <v-app-bar
      clipped-left
      app
      color="indigo"
      dark
      src="../../assets/images/picsum_865-1920x1080.jpg"
    >
      <AppSnackbar data-testid="snackBarBanner" />
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        data-testid="navbarButton"
      />
      <v-toolbar-title>
        {{ $t("groupAlert") }}
        <span v-if="serverName !== 'prod'">
          {{ serverName.toUpperCase() }}
        </span>
      </v-toolbar-title>
      <v-spacer />
      <LocaleSwitcher />
    </v-app-bar>

    <v-navigation-drawer
      clipped
      v-model="drawer"
      app
      dark
      mobile-breakpoint="1700"
    >
      <v-list class="mb-n4">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">
              {{ userFullName }}
            </v-list-item-title>
            <v-list-item-subtitle class="mb-1">
              {{ userCompany }}
            </v-list-item-subtitle>

            <v-list-item-subtitle
              v-if="!userIsAdmin && userDepartments?.length === 2"
            >
              {{ userDepartments[1].text }}
            </v-list-item-subtitle>

            <v-autocomplete
              v-if="!userIsAdmin && userDepartments.length > 2"
              :label="$t('department')"
              :items="userDepartments"
              @change="setDepartment"
              v-model="selectedDepartment"
              class="mt-4"
              outlined
              dense
              data-testid="departmentSelectNav"
            />
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="userIsAdmin && customerHasDepartments">
          <v-list-item-content>
            <DepartmentsSelect
              :init-value="selectedDepartment"
              v-on:change="setDepartment"
              data-testid="departmentSelectNav"
            />
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>
      <v-list dense>
        <v-list-item-group
          data-testid="navbarGroup"
          color="white"
          v-model="currentPathIndex"
        >
          <v-list-item
            data-testid="navbarItem"
            link
            v-for="(item, i) in navDrawerItems"
            :key="i"
            @click="navigate(item.path)"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            sub-group
            :value="false"
            v-if="userIsAdmin || userHasUserManagementPermission"
            data-testid="navbarAdminGroup"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t("administration") }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-if="userHasUserManagementPermission"
              link
              @click="navigate('/usrMng')"
            >
              <v-list-item-action>
                <v-icon>mdi-account-supervisor</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("systemUsers") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="userIsAdmin"
              link
              @click="navigate('/admin/departments')"
            >
              <v-list-item-action>
                <v-icon>mdi-shield-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("departments") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              data-testid="navbarAdminItem"
              link
              @click="navigate('/admin/roles')"
              v-if="userIsAdmin && customerUsesRoles"
            >
              <v-list-item-action>
                <v-icon>mdi-key</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("roles") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="userIsAdmin"
              link
              @click="navigate('/admin/stats')"
            >
              <v-list-item-action>
                <v-icon>mdi-chart-pie</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("stats") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item link @click="toggleTheme">
            <v-list-item-action>
              <v-icon>mdi-theme-light-dark</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t("changeTheme") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link v-if="isV24User" @click="backToV24()">
            <v-list-item-action>
              <v-icon>mdi-arrow-left</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t("backToV24") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item data-testid="navbarButtonLogout" link @click="logOut()">
            <v-list-item-action>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t("logOut") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import router from "../../router";
import user from "../../util/user";
import store from "../../store/index";
import config from "../../config/config";
import LocaleSwitcher from "../misc/LocaleSwitcher";
import AppSnackbar from "@/components/misc/AppSnackbar";
import appSnackbar from "@/util/appSnackbac";
import DepartmentsSelect from "@/components/departments/DepartmentsSelect";
import apiDepartment from "@/api/v24/api.department";
import eventLogger from "@/util/eventLogger";

export default {
  name: "MainNavigation",
  components: { DepartmentsSelect, AppSnackbar, LocaleSwitcher },

  data: () => ({
    item: null,
    drawer: null,
    isV24User: false,
    serverName: config.serverName,
    navItemIndex: null,
    selectedDepartment: user.getSelectedDepartment()
  }),

  computed: {
    userIsAdmin() {
      if (store.state.userProfile) {
        return store.state.userProfile.Bruker.Admin;
      }
      return false;
    },
    customerHasDepartments() {
      return store.getters.getCustomerHasDepartments;
    },
    userHasUserManagementPermission() {
      return store.state.userProfile?.Bruker._permissions.manageUsers;
    },
    userFullName() {
      return store.getters.getUserFullName;
    },
    userCompany() {
      return store.getters.getUserCompany;
    },
    customerUsesRoles() {
      return store.state.userProfile?.Rollestyring;
    },
    userDepartments() {
      const departments = [];
      const usrData = store.state.userProfile?.Bruker;
      if (!usrData) {
        return [];
      }
      departments.push({
        text: this.$t("showAllDepartments"),
        value: ""
      });
      if (usrData.Avdeling) {
        departments.push({
          text: usrData.AvdelingNavn,
          value: usrData.Avdeling
        });
      }
      usrData.Avdelinger.forEach(dep => {
        departments.push({ text: dep.Navn, value: dep.AvdelingId });
      });
      return departments;
    },
    navDrawerItems() {
      const navItems = [
        {
          text: this.$t("alert"),
          icon: "mdi-send",
          path: "/alert"
        },
        {
          text: this.$t("log"),
          icon: "mdi-history",
          path: "/log"
        }
      ];
      if (
        store.state.userProfile &&
        store.state.userProfile.Bruker._permissions.dsfModule
      ) {
        navItems.push({
          text: this.$t("extractionFromDsf"),
          icon: "mdi-database-arrow-down-outline",
          path: "/dsf-extract"
        });
      }
      navItems.push(
        ...[
          {
            text: this.$t("contactAndGrpMng"),
            icon: "mdi-account-group",
            path: "/management"
          },
          {
            text: this.$t("alertTemplates"),
            icon: "mdi-pencil-ruler",
            path: "/alert-templates"
          }
        ]
      );

      if (store.state.userProfile?.Bruker._permissions.files) {
        navItems.push({
          text: this.$t("files"),
          icon: "mdi-file-document-outline",
          path: "/files"
        });
      }

      navItems.push(
        ...[
          {
            text: this.$t("scenario"),
            icon: "mdi-gesture-tap",
            path: "/scenario"
          },
          {
            text: this.$t("myProfile"),
            icon: "mdi-account",
            path: "/user-profile"
          },
          {
            text: this.$t("help"),
            icon: "mdi-help-circle-outline",
            path: "/feedback"
          }
        ]
      );
      return navItems;
    },
    currentPathIndex: {
      get() {
        if (this.navItemIndex) {
          return this.navItemIndex;
        }
        let i = 0;
        const len = this.navDrawerItems.length;
        for (i; i < len; i++) {
          const currentPath = this.$route.path;
          if (currentPath === this.navDrawerItems[i].path) {
            return i;
          }
        }
        return null;
      },
      set(index) {
        this.navItemIndex = index;
      }
    }
  },

  created() {
    this.isV24User = user.getIsV24User();
  },

  methods: {
    setDepartment(department) {
      if (department === null) {
        // User has clear search to empty string. Don't emit change event
        return;
      }
      user.setSelectedDepartment(department);
    },
    navigate(path) {
      router.push(path);
    },
    backToV24() {
      window.location.href = config.urlV24MainMenu;
    },
    logOut() {
      user.logOut();
    },
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      const lastUsedTheme = this.$vuetify.theme.dark ? "dark" : "light";
      user.setLasedUsedTheme(lastUsedTheme);
      eventLogger.themeChange(lastUsedTheme);
    }
  }
};
</script>
