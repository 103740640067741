import api from "./api";
import config from "@/config/config";
import user from "@/util/user";
import store from "@/store";

const apiUser = {
  alias: "V24",

  /**
   * @name getProfile
   * @desc Gets the user profile for the logged in user
   * @returns {Promise<unknown>}
   */
  getProfile() {
    return new Promise(resolve => {
      let promiseDepartments;
      const promiseProfile = api
        .execute("HentBrukerProfil", null, "Profil")
        .then(usrProfile => {
          getAdminDepartments(usrProfile).then(departments => {
            // The API returns to objects defining permissions. One legacy
            // object (Tilgang) and one new (Rettighet).
            // Rettighet is the permission
            // a user has based on permission roles given. At the moment
            // the roles feature is enabled for only a selected group of
            // customers that will have Rollestyring=true.
            //
            // To avoid tons of if-else we here map the
            // permission flags to a custom _permission field, and will use that
            // instead of Tilgang/Rettighet
            //
            // But note that if a customer has Rollestyring, some of the fields
            // in Tilgang is still used! For example: Rigspolitiet
            // has Rollestyring, and they also have a feature called
            // Tjenestetelefon, that is defined in the Tilgang object

            const rolePerm = usrProfile.Bruker.Rettighet;
            const legacyPerm = usrProfile.Bruker.Tilgang;
            const r = usrProfile.Rollestyring;
            const p = {
              dsfModule: legacyPerm.GruppeDSF,
              activeDirProvisioning: legacyPerm.ADProvisjonering,
              template: r ? rolePerm.Mal : legacyPerm.Maler,
              alert: r ? rolePerm.Varsling : legacyPerm.GruppeVarsling,
              manageContacts: r ? rolePerm.Kontakt : legacyPerm.Grupper,
              manageAccount: r ? rolePerm.Konto : usrProfile.Administrator,
              manageUsers: r ? rolePerm.Bruker : false,
              files: legacyPerm.Filer,
              phoneNumTypes: legacyPerm.TjenesteTelefon
            };

            // Admins have access to everything that is not a per customer
            // permission level
            if (usrProfile.Bruker.Admin) {
              p.template = true;
              p.alert = true;
              p.manageContacts = true;
              p.manageAccount = true;
              p.manageUsers = true;
            }

            usrProfile.Bruker._permissions = p;
            setDepartment(usrProfile);

            // It's practical for us to have the customers/admin departments in the
            // user profile. So we merge them together
            usrProfile._adminDepartments = departments;
            resolve(usrProfile);
          });
        });
    });
  },
  changeProfile(name, email, mobile) {
    const params = { Navn: name, Epost: email, Mobil: mobile };
    return api.execute("EndreBrukerProfil", params);
  },

  requestNewPassword(userId, messageToReceiver) {
    const params = {
      BrukerId: userId,
      Alias: this.alias,
      Mal: {
        Avsender: config.appName,
        Melding: messageToReceiver + " %d"
      }
    };
    return api.execute("RequestNewPassword", params, null, false);
  },

  confirmNewPassword(userId, token, newPassword) {
    const params = {
      BrukerId: userId,
      Token: token,
      Hemmelighet: newPassword,
      Alias: this.alias
    };
    return api.execute("ConfirmNewPassword", params, null, false, true);
  }
};

const getAdminDepartments = userProfile => {
  return new Promise(resolve => {
    if (!userProfile.Bruker.Admin) {
      resolve([]);
    } else {
      store
        .dispatch("updateDepartments")
        .then(departments => resolve(departments));
    }
  });
};

const setDepartment = userProfile => {
  if (userProfile.Bruker.Admin) {
    return;
  }

  if (!userProfile.Bruker.Avdelinger.length) {
    return;
  }

  const sessionDepartment = user.getSelectedDepartment();

  // This will handle the situation where a department user has not any
  // department stored in sessionStorage (may be because a new tab has been
  // created with a valid API session id (stored in localStorage)).
  if (
    !sessionDepartment &&
    userProfile.Bruker.Avdeling &&
    !userProfile.Bruker.Avdelinger.length
  ) {
    user.setSelectedDepartment(userProfile.Bruker.Avdeling);
  }
};

export default apiUser;
