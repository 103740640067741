import numberUtil from "@/util/numberUtil";
import config from "@/config/config";

export default class Email {
  constructor(emailAddr) {
    this.emailAddr = emailAddr;
  }

  isValid() {
    // Not allowing following chars that can be accidentally get copy-pasted
    const copyPasteFails = ["<", ">", ","];
    let i = 0;
    const len = copyPasteFails.length;
    for (i; i < len; i++) {
      if (this.emailAddr.indexOf(copyPasteFails[i]) !== -1) {
        return false;
      }
    }

    return /\S+@\S+\.\S+/.test(this.emailAddr);
  }
}
