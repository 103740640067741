/*
Server config for prod.
Used by server-conf-generator.js
There is one conf-x.js file per server type. Remember to edit all files
if a new field is added
 */
const serverConfig = {
  name: "prod",
  url: "https://app.groupalert.net/",
  apiUrl: "https://v24api.net/api/",
  apiAudioUrl: "https://v24api.net/audio/",
  apiSsoUrl: "https://v24api.net/sso/",
  v24Url: "https://varsling24.net/",
  callerId: "+4756914099",
};
export default serverConfig;