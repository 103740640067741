<template>
  <v-autocomplete
    v-model="selected"
    @change="change"
    :items="departments"
    item-text="Navn"
    item-value="AvdelingId"
    :loading="!departments"
    outlined
    dense
    :label="$t('department')"
  />
</template>

<script>
import apiDepartment from "@/api/v24/api.department";
import store from "@/store";

export default {
  name: "DepartmentsSelect",

  props: {
    initValue: {
      default: null
    }
  },

  computed: {
    departments() {
      if (!store.state.userProfile) {
        return null;
      }
      const usrProfile = store.state.userProfile;
      if (usrProfile.Bruker.Admin) {
        const allDeps = structuredClone(usrProfile._adminDepartments);
        allDeps.unshift({
          Navn: this.$t("showAllDepartments"),
          AvdelingId: ""
        });
        return allDeps;
      }
      return store.state.departments;
    }
  },

  data: () => ({
    selected: null
  }),

  created() {
    console.log(this.initValue);
    if (this.initValue || this.initValue === "") {
      this.selected = this.initValue;
    }
    store.dispatch("updateDepartments");
    store.dispatch("updateUserProfile");
  },

  methods: {
    change() {
      if (this.selected === null) {
        // User has clear search to empty string. Don't emit change event
        return;
      }
      this.$emit("change", this.selected);
    }
  }
};
</script>
