// See https://github.com/FramWeb/V24CoreApi/issues/909
import api from "./api";

const apiEvent = {
  set(operation, data) {
    const params = {
      Operation: operation,
      Params: data
    };
    return api.execute("Event", params, null, false, true);
  }
};

export default apiEvent;
