import api from "./api";
import user from "@/util/user";

const apiGroup = {
  get(id) {
    return new Promise(resolve => {
      api.execute("HentGruppe", { Id: id }, "Grupper").then(groups => {
        resolve(groups[0]);
      });
    });
  },
  getMembers(
    groupId,
    pageIndex,
    numberOfRows,
    sortField = "",
    sortDesc = false
  ) {
    const params = {
      GruppeId: groupId,
      Paging: {
        RowCount: numberOfRows,
        PageIdx: pageIndex
      },
      SorterFelt: sortField,
      SorterSynkende: sortDesc
    };
    return api.execute("HentMedlemmer", params);
  },
  searchMembers(
    groupId,
    searchStr,
    pageIndex,
    numberOfRows,
    sortField = "",
    sortDesc = false
  ) {
    const params = {
      GruppeId: groupId,
      Paging: {
        RowCount: numberOfRows,
        PageIdx: pageIndex
      },
      SorterFelt: sortField,
      SorterSynkende: sortDesc,
      Tekst: searchStr,
      StarterMed: false
    };
    return api.execute("SokMedlemmer", params);
  },
  save(id, name, department = "", shortname = "") {
    const params = {
      Gruppe: {
        Id: id,
        Navn: name,
        Avdeling: department,
        KodeNavn: shortname
      }
    };
    return api.execute("EndreGruppe", params);
  },
  getAll(silent, forceFetchAll) {
    const department = forceFetchAll ? "" : user.getSelectedDepartment();
    const params = { Avdeling: department };
    return api.execute("HentGrupper", params, "Grupper", true, silent);
  },
  addContact(groupId, contactId) {
    const params = { GruppeId: groupId, KontaktId: contactId };
    return api.execute("NyttMedlem", params);
  },
  removeContact(groupId, contactId) {
    const params = { GruppeId: groupId, KontaktId: contactId };
    return api.execute("slettMedlem", params);
  },
  create(name, department = "", shortword = "") {
    if (!department) {
      department = user.getSelectedDepartment();
    }
    const params = {
      Gruppe: {
        Navn: name,
        Avdeling: department,
        KodeNavn: shortword
      }
    };
    return new Promise((resolve, reject) => {
      api
        .execute("NyGruppe", params, "Gruppe", true, true)
        .then(group => {
          resolve(group);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  /**
   * Deletes group. Note: Will throw error 413 if group is tied to a
   * scenario. Calling component is responsable to handle this.
   * @param id
   * @param deleteContacts
   * @returns {Promise}
   */
  delete(id, deleteContacts = true) {
    const params = {
      Id: id,
      SlettKontakter: deleteContacts
    };
    return new Promise((resolve, reject) => {
      api
        .execute("SlettGruppe", params, null, true, true)
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  /**
   * Deletes members from group
   * @param members Array with objects containing GruppeId and KontaktId
   */
  deleteMembers(members) {
    return new Promise((resolve, reject) => {
      const params = { Medlemmer: members };
      api
        .execute("SlettMedlemmer", params, null, true, true)
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteAll(ids, deleteContacts) {
    const params = {
      Ids: ids,
      SlettKontakter: deleteContacts
    };
    return new Promise((resolve, reject) => {
      api
        .execute("SlettGrupper", params, null, true, true)
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getNumberOfMembers(groupId) {
    return api.execute(
      "HentAntallMedlemmer",
      { GruppeId: groupId },
      "AntallTotalt"
    );
  }
};

export default apiGroup;
