import numberUtil from "@/util/numberUtil";

const stringUtil = {
  /**
   * Converts whitespace not supported by SMS/email (will result in question
   * mark) to "normal" white space
   * @param {String} text
   * @returns {String} Fixed text
   */
  fixUnsupportedWhitespace(text) {
    return text.replace(" ", " ");
  },

  truncate(string, limit) {
    if (string.length > limit) {
      string = string.substring(0, limit);
    }
    return string;
  },

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },

  hasNorwegianLetters(string) {
    return (
      string.indexOf("æ") >= 0 ||
      string.indexOf("Æ") >= 0 ||
      string.indexOf("ø") >= 0 ||
      string.indexOf("Ø") >= 0 ||
      string.indexOf("å") >= 0 ||
      string.indexOf("Å") >= 0
    );
  },

  hasLowerCase(string) {
    return string.toUpperCase() !== string;
  },

  hasUpperCase(string) {
    return string.toLowerCase() !== string;
  },

  hasWhitespace(string) {
    return /\s/.test(string);
  },

  hasNumber(string) {
    let i = string.length;
    while (i--) {
      if (numberUtil.isNumber(string.charAt(i))) {
        return true;
      }
    }
    return false;
  },

  /**
   * Replaces URL/links with HTML a tag for the link(s)
   * @param {String} text
   * @param {Boolean} openUrlInNewTab
   * @returns {String}
   */
  urlify(text, openUrlInNewTab = false) {
    const targetAttr = openUrlInNewTab ? ' target="_blank"' : "";
    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return text.replace(urlRegex, function(url) {
      return '<a href="' + url + '"' + targetAttr + ">" + url + "</a>";
    });
  },

  covertLineShiftToHtmlBreak(text) {
    return text.replace(/\n/g, "<br />");
  },

  makeDownloadFriendlyName(fileName) {
    return fileName.replace(/[^a-z0-9._-]+/gi, "_");
  },

  createRandomString() {
    return Math.random()
      .toString(36)
      .slice(-6);
  },

  /**
   * Escapes special characters in a string so it can be used in a regular
   * expression. Javascript does not have a built-in function for this.
   * This is from Mozilla Developer Network:
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
   * @param string
   * @returns {*}
   */
  escapeRegExp(string) {
    // $& means the whole matched string
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }
};

export default stringUtil;
