import api from "./api";
import numberUtil from "@/util/numberUtil";
import user from "@/util/user";

const apiContact = {
  get(id) {
    return new Promise((resolve, reject) => {
      api
        .execute("HentKontakt", { Id: id }, "Kontakter")
        .then(res => {
          resolve(res[0]);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getMultiple(
    pageIndex,
    numberOfRows,
    sortField = "",
    sortDesc = false,
    abortSignal = null
  ) {
    let params = null;
    if (pageIndex && numberOfRows) {
      params = {
        Paging: {
          RowCount: numberOfRows,
          PageIdx: pageIndex
        },
        SorterFelt: sortField,
        SorterSynkende: sortDesc,
        Avdeling: user.getSelectedDepartment()
      };
    }
    return api.execute(
      "HentKontakter",
      params,
      null,
      true,
      null,
      "",
      abortSignal
    );
  },

  getAll(abortSignal = null) {
    const pageSize = 10000; // 10 000 ett ok antall? Tilsvarer ca 3.4 mb data og ca 1 sec
    let pageIndex = 1;
    let allContacts = [];

    const fetchChunk = () => {
      if (abortSignal && abortSignal.aborted) {
        throw new Error("Operation cancelled");
      }
      return this.getMultiple(pageIndex, pageSize, "", false, abortSignal).then(
        res => {
          if (res.Kontakter && res.Kontakter.length > 0) {
            allContacts = allContacts.concat(res.Kontakter);
            pageIndex++;
            return fetchChunk();
          } else {
            return allContacts;
          }
        }
      );
    };

    return new Promise((resolve, reject) => {
      fetchChunk()
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * @name search
   *
   * @param searchString
   * @param pageIndex
   * @param numberOfRows
   * @param sortField
   * @param sortDesc
   * @param contactsToIgnore Array of contact IDs to ignore
   * @param groupToIgnore Group ID to ignore. If this is set,
   * contactsToIgnore is... ignored...
   * @returns {Promise<unknown>}
   */
  search(
    searchString,
    pageIndex,
    numberOfRows,
    sortField = "",
    sortDesc = false,
    contactsToIgnore = [],
    groupToIgnore = null
  ) {
    const ignoreIds = contactsToIgnore.map(contactToIgnore => {
      if (numberUtil.isNumber(contactToIgnore)) {
        return contactToIgnore;
      }
      return contactToIgnore.Id;
    });
    // API allows search with wildcard. We do this since that is the way it's
    // done when we search with custom-filter in datatables
    let searchStringWildcard = searchString.replace(" ", "%");
    const params = {
      Tekst: searchStringWildcard,
      StarterMed: false,
      Paging: {
        RowCount: numberOfRows,
        PageIdx: pageIndex
      },
      SorterFelt: sortField,
      SorterSynkende: sortDesc,
      Unntak: ignoreIds,
      UnntakGruppe: groupToIgnore,
      Avdeling: user.getSelectedDepartment()
    };
    return api.execute("SokKontakter", params);
  },

  /**
   * @name create
   * @param {Contact} contact Instance of class Contact
   * @returns {Promise}
   */
  create(contact) {
    return new Promise(resolve => {
      const params = {
        GruppeId: 0,
        Kontakter: [contact.getAsApiObject()],
        Avdeling: user.getSelectedDepartment()
      };
      createContacts(params).then(res => {
        resolve(res[0]);
      });
    });
  },

  /**
   *
   * @param {Contacts} contacts
   * @param groupId
   */
  createContactsAndAddToGroup(contacts, groupId) {
    const params = {
      GruppeId: groupId,
      Kontakter: contacts.getAsApiFormat()
    };
    return createContacts(params);
  },

  change(contact) {
    const params = {
      Kontakt: contact.getAsApiObject()
    };
    return api.execute("EndreKontakt", params);
  },

  delete(id) {
    return api.execute("SlettKontakt", { Id: id });
  },

  deleteMultiple(ids, silent = false) {
    return new Promise((resolve, reject) => {
      api
        .execute("SlettKontakter", { Ids: ids }, null, true, silent)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getContactsInGroup(groupId) {
    return api.execute("HentMedlemmer", { GruppeId: groupId }, "Medlem");
  },

  getContactDeputies(contactsId) {
    return api.execute(
      "HentKontaktVikarer",
      { KontaktIds: contactsId },
      "KontaktVikarer"
    );
  }
};

const createContacts = params => {
  return new Promise(resolve => {
    api.execute("NyKontakt", params, "Kontakter").then(contacts => {
      const serverErrs = contacts.filter(contact => contact.Status !== "OK");
      if (serverErrs.length) {
        // Should not come here since all input should valid. This is the "last
        // line of defense"
        const errorMsg =
          "Server rejected " +
          serverErrs.length +
          " contacts. " +
          "Review your data or contact Fram Web for support";
        alert(errorMsg);
      }
      resolve(contacts);
    });
  });
};

export default apiContact;
