import configVoice from "@/config/configVoice";

export default class AlertTemplate {
  constructor() {
    this.id = 0;
    this.name = "";
    this.alertName = "";
    this.text = {
      sms: "",
      email: "",
      voice: ""
    };
    this.responseOptions = null;
    this.emailTitle = "";
    this.voiceSettings = {
      voiceProps: {
        Gender: "",
        Id: "",
        LanguageCode: "",
        LanguageName: "",
        Name: ""
      },
      attempts: configVoice.defaultSetting.attempts,
      delay: configVoice.defaultSetting.delay,
      endOnState: configVoice.defaultSetting.endOnState,
      deputy: configVoice.defaultSetting.deputy
    };
    this.responseOptions = configVoice.defaultSetting.responseOptions;
  }

  /**
   * Set the alert template properties from object returned from API.
   * @param {Object} apiObject
   */
  setFromApiObject(apiObject) {
    const templData = JSON.parse(apiObject.Melding);
    this.id = apiObject.MalId;
    this.name = apiObject.Navn;
    this.department = apiObject.Avdeling;
    this.departmentName = apiObject.AvdelingNavn;
    this.text = templData.text;
    this.responseOptions = templData.responseOptions;
    // Following fields defaults to empty since this field was introduced
    // after initial format and may not exits for all templates
    if (templData.alertName) {
      this.alertName = templData.alertName;
    }
    if (templData.emailTitle) {
      this.emailTitle = templData.emailTitle;
    }
    if (templData.voiceSettings) {
      this.voiceSettings = templData.voiceSettings;
    }
  }

  get() {
    return {
      id: this.id,
      name: this.name,
      alertName: this.alertName,
      department: this.department,
      departmentName: this.departmentName,
      text: this.text,
      emailTitle: this.emailTitle,
      voiceSettings: this.voiceSettings,
      responseOptions: this.responseOptions
    };
  }
}
