// See https://github.com/FramWeb/V24CoreApi/issues/909
// See https://github.com/FramWeb/V24CoreApi/issues/909
import apiEvent from "@/api/v24/api.event";
import eventLogConfig from "@/config/eventLogConfig";

const operation = eventLogConfig.operation;

const eventLogger = {
  ageFilter(fromYear, toYear, kommuneNum) {
    sendEvent(operation.ageFilter, {
      fromYear: fromYear,
      toYear: toYear,
      kommuneNum: kommuneNum
    });
  },

  themeChange(theme) {
    sendEvent(operation.themeChange, { themeType: theme });
  },

  importReceiversFromAlertPage() {
    sendEvent(operation.importReceiversFromAlertPage);
  },

  extraReceiverUsed() {
    sendEvent(operation.extraReceiverUsed);
  },

  receiversAddedFromDsf() {
    sendEvent(operation.receiversAddedFromDsf);
  },

  voiceUsedInAlert(alertObj) {
    sendEvent(operation.voiceUsedInAlert, createAudioParamObj(alertObj));
  },

  voiceFileUsedInAlert(alertObj) {
    sendEvent(operation.voiceFileUsedInAlert, createAudioParamObj(alertObj));
  },

  voiceFileRecordedOnAlertPage() {
    sendEvent(operation.voiceFileRecordedOnAlertPage);
  },

  voiceFileRecorded() {
    sendAudioEvent(operation.voiceFileRecorded);
  },

  voiceFileUploaded() {
    sendEvent(operation.voiceFileUploaded);
  },

  voiceFileEdited() {
    sendAudioEvent(operation.voiceFileEdited);
  },

  emailFileUploaded() {
    sendEmailFileEvent(operation.emailFileUploaded);
  },

  emailFileEdited() {
    sendEmailFileEvent(operation.emailFileEdited);
  },

  dsfExctraction(count) {
    sendEvent(operation.dsfExctraction, { numberOfPersons: count });
  },

  scenarioUsed() {
    sendEvent(operation.scenarioUsed);
  }
};

const sendAudioEvent = (operation, audioId) => {
  sendEvent(operation, { audioId: audioId });
};

const sendEmailFileEvent = (operation, fileId) => {
  sendEvent(operation, { fileId: fileId });
};

const sendEvent = (operation, data) => {
  const params = createEventParamFormat(data);
  return apiEvent.set(operation, params);
};

const createAudioParamObj = alertObj => {
  const voiceProps = alertObj.voice.voiceProps;
  return {
    alertName: alertObj.name,
    simMode: alertObj.simMode,
    audioFile: alertObj.audioFile,
    voiceName: voiceProps.Name,
    voiceLanguage: voiceProps.LanguageName,
    voiceGender: voiceProps.Gender,
    endOnState: alertObj.voice.endOnState,
    maxAttempts: alertObj.voice.attempts?.maxAttempts || "",
    delayBetweenAttempts: alertObj.voice.attempts?.delayBetweenAttempts || ""
  };
};

const createEventParamFormat = data => {
  let strParam = "";
  for (const key in data) {
    if (strParam) {
      strParam += ", ";
    }
    strParam += key + "=" + data[key];
  }
  return strParam;
};

export default eventLogger;
