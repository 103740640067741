<template>
  <div>
    <v-form @submit.prevent="login()">
      <v-flex>
        <v-text-field
          data-testid="loginFormEmail"
          autofocus
          autocomplete="username"
          v-model="email"
          :label="$t('email')"
          required
        />
      </v-flex>

      <v-flex>
        <v-text-field
          data-testid="loginFormPassword"
          v-model="password"
          type="password"
          placeholder=" "
          :label="$t('pass')"
          required
          autocomplete="current-password"
        />
      </v-flex>

      <v-alert type="info" class="mt-8" v-if="resetPassSuccessMsg">
        {{ $t("passResetSuccess") }}
      </v-alert>

      <v-alert type="warning" v-if="wrongLogin">
        {{ $t("wrongLogin") }}
      </v-alert>

      <v-alert type="warning" v-if="loginFail">
        {{ $t("wrongLogin") }}
      </v-alert>

      <v-alert type="error" v-if="accountDisabled">
        {{ $t("tooManyPassAttempts") }}
      </v-alert>

      <v-flex xs12>
        <v-btn
          data-testid="loginFormLogin"
          class="primary"
          large
          block
          type="submit"
          >{{ $t("login") }}</v-btn
        >
      </v-flex>

      <v-flex xs12 class="mb-15 mt-10">
        <v-btn
          data-testid="loginFormResetPassword"
          text
          small
          style="float: right"
          outlined
          @click="showResetPassDialog"
        >
          {{ $t("resetPassword") }}
        </v-btn>
      </v-flex>
    </v-form>

    <v-dialog
      v-model="resetPassDialog"
      max-width="600"
      v-if="resetPassDialog"
      persistent
    >
      <NewPasswordRequest
        :init-email="email"
        v-on:cancel="closeResetDialog"
        v-on:success="showResetSuccessMsg"
      />
    </v-dialog>

    <v-dialog v-model="twoFaDialog" width="300" persistent>
      <v-form @submit.prevent="validatePin()" autocomplete="off">
        <v-card>
          <v-card-title class="headline" primary-title>
            {{ $t("pinRequired") }}
          </v-card-title>

          <v-card-text>
            <p>
              {{ $t("pinSentToMobile") }}
            </p>
            <!-- v-if to retrigger autofocus -->
            <v-text-field
              v-model="pin"
              :label="$t('pin')"
              outlined
              autofocus
              v-if="twoFaDialog"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="cancelPin">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn type="submit" color="primary" :disabled="!pin">
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import user from "../../util/user";
import NewPasswordRequest from "@/components/auth/NewPasswordRequest";
import store from "@/store";
import apiAdmin from "@/api/v24/api.admin";

export default {
  name: "LoginForm",
  components: { NewPasswordRequest },
  data() {
    return {
      email: "",
      forgotPasswordEmail: "",
      resetPassSuccessMsg: "",
      password: "",
      twoFaDialog: false,
      resetPassDialog: false,
      pin: "",
      loginFail: false,
      accountDisabled: false,
      wrongLogin: false
    };
  },
  methods: {
    login() {
      this.loginFail = false;
      this.accountDisabled = false;
      this.uknownUser = false;
      this.resetPassSuccessMsg = "";
      user
        .logIn(this.email, this.password, true)
        .then(twoFaRequried => {
          if (twoFaRequried) {
            this.show2FaDialog();
          } else {
            userIsGoodToGo();
          }
        })
        .catch(err => {
          if (err.response && err.response.status) {
            if (err.response.status === 400) {
              this.wrongLogin = true;
              return;
            } else if (err.response.status === 401) {
              this.loginFail = true;
              return;
            } else if (err.response.status === 423) {
              this.accountDisabled = true;
              return;
            }
          }
          console.log(err);
          alert("Server error. Unable to log in");
        });
    },
    show2FaDialog() {
      this.pin = "";
      this.twoFaDialog = true;
    },
    showResetPassDialog() {
      this.loginFail = false;
      this.forgotPasswordEmail = this.email;
      this.resetPassDialog = true;
    },
    showResetSuccessMsg() {
      this.resetPassDialog = false;
      this.resetPassSuccessMsg = this.$t("passResetSuccess");
    },
    closeResetDialog() {
      this.resetPassDialog = false;
    },
    cancelPin() {
      this.twoFaDialog = false;
    },
    validatePin() {
      user
        .logInPin(this.email, this.password, this.pin)
        .then(() => {
          userIsGoodToGo();
        })
        .catch(errMsg => {
          alert(errMsg);
        });
    }
  }
};

const userIsGoodToGo = () => {
  setDepartment();
  user.goToStartPage();
};

const setDepartment = () => {
  const mainDep = store.state.userProfile.Bruker.Avdeling || "";
  user.setSelectedDepartment(mainDep);
};
</script>
