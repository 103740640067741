// Moved this code out of user.js since we got some issues when importing
// that module in config.js

import storage from "@/util/storage";

const userLocale = {
  get() {
    return storage.getLocalItem("locale") || "";
  }
};

export default userLocale;
