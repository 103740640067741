<template>
  <div class="background">
    <v-container fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card max-width="450" class="elevation-24">
            <v-card-text>
              <v-layout align-center justify-center>
                <v-img
                  class="ma-12"
                  src="../assets/images/logo/logo-with-app-title.svg"
                  max-width="400"
                ></v-img>
              </v-layout>
              <LoginForm />
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import LoginForm from "../components/auth/LoginForm";

export default {
  name: "LandingPage",
  components: { LoginForm }
};
</script>

<style scoped>
.background {
  position: relative;
  height: 100%;
  background-image: url("../assets/images/picsum_865-1920x1080.jpg");
  background-size: cover;
  overflow: auto;
}
</style>
