<template>
  <div>
    <v-form
      v-model="formSendCodeValid"
      @submit.prevent="sendRequest"
      v-if="emailField"
      autocomplete="off"
    >
      <p>
        <slot name="enterEmailText"></slot>
      </p>
      <v-text-field
        v-model="email"
        v-if="!hideEmail"
        :label="$t('email')"
        :rules="emailRules"
        autofocus
        autocomplete="off"
        required
        outlined
      />
      <div class="d-flex justify-center">
        <v-btn
          type="submit"
          class="primary"
          large
          :disabled="!formSendCodeValid"
          data-testid="sendPasswordCreator"
        >
          {{ $t("sendCodeToMob") }}
        </v-btn>
      </div>
    </v-form>
    <v-form
      @submit.prevent="validateToken"
      v-if="tokenField"
      autocomplete="off"
    >
      <p>
        {{ $t("enterCodeInfo") }}
      </p>
      <v-text-field
        v-model="token"
        :label="$t('code')"
        outlined
        required
        autofocus
        autocomplete="new-password"
      />
      <v-text-field
        v-model="newPassword"
        :label="$t('newPassword')"
        autocomplete="new-password"
        :rules="passwordRules"
        maxlength="32"
        type="password"
        outlined
        required
      />
      <v-alert type="error" v-if="errorMsg">
        {{ errorMsg }}
      </v-alert>
      <div class="d-flex justify-center">
        <v-btn type="button" large class="mr-4" @click.prevent="back">
          {{ $t("back") }}
        </v-btn>
        <v-btn
          type="submit"
          class="primary"
          large
          :disabled="!token || !newPassword"
        >
          {{ $t("ok") }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import formValidationRules from "@/util/formValidationRules";
import apiUser from "@/api/v24/api.user";

export default {
  name: "PasswordCreator",

  props: {
    initEmail: {
      type: String,
      default: ""
    },
    hideEmail: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    email: "",
    emailField: true,
    tokenField: false,
    tokenValidField: false,
    token: "",
    newPassword: "",
    errorMsg: "",
    requiredRules: formValidationRules.requiredRule,
    emailRules: formValidationRules.requiredEmailRules,
    passwordRules: formValidationRules.passwordRules,
    formSendCodeValid: false
  }),

  created() {
    this.email = this.initEmail;
  },

  methods: {
    back() {
      this.tokenField = false;
      this.emailField = true;
    },
    sendRequest() {
      const messageToReceiver = this.$t("codeNewPassInfo");
      apiUser.requestNewPassword(this.email, messageToReceiver).then(() => {
        this.emailField = false;
        this.tokenField = true;
      });
    },
    validateToken() {
      this.errorMsg = "";
      apiUser
        .confirmNewPassword(this.email, this.token, this.newPassword)
        .then(() => {
          this.requestOk();
        })
        .catch(err => {
          if (err.response && err.response.status) {
            if (err.response.status === 401) {
              this.errorMsg = this.$t("invalidCode");
            } else if (err.response.status === 406) {
              this.errorMsg = this.$t("passNotAccept");
            } else {
              alert(err.response.statusText);
            }
          } else {
            alert(this.$t("codeRequestFailed"));
          }
        });
    },
    requestOk() {
      this.$emit("success", { email: this.email, password: this.newPassword });
    }
  }
};
</script>
