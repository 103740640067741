import axios from "axios/index";
import config from "../../config/config";
import user from "../../util/user";
import i18n from "@/i18n";

const api = {
  execute: (
    methodName,
    params = null,
    returnField = null,
    logoutOnUnauth = true,
    silent = false,
    formData = null,
    apiPath = "",
    abortSignal = null
  ) => {
    return new Promise((resolve, reject) => {
      let url = "";
      if (apiPath === "audio") {
        url = config.urlV24AudioApi;
      } else {
        url = config.urlV24Api;
      }
      url += methodName;
      const sessionId = user.getSessionId();
      let axiosParams = {
        method: "post",
        url: url,
        headers: {
          // For backend stats purpose
          "X-V24-Service": "GA"
        },
        abortSignal: abortSignal
      };
      if (params) {
        axiosParams.data = JSON.stringify(params);
      } else if (formData) {
        axiosParams.data = formData;
        axiosParams.headers["content-type"] = "multipart/form-data";
      }
      if (sessionId) {
        axiosParams.headers.SessionId = sessionId;
      }

      axios(axiosParams)
        .then(res => {
          if (returnField) {
            resolve(res.data[returnField]);
          } else {
            resolve(res.data);
          }
        })
        .catch(err => {
          if (err.response?.status === 401 && logoutOnUnauth) {
            user.logOutClient();
            return;
          }
          if (err.response?.data?.Errors?.length) {
            checkAccessRights(err.response.data.Errors);
            addCustomHintFields(err);
            if (!silent) {
              alert(parseOutErrorMsg(err));
            }
            reject(err);
          } else {
            if (!silent) {
              handleNoResponse(err);
            }
            reject(err);
          }
        });
    });
  }
};

const addCustomHintFields = err => {
  const code = err.response.data.Errors[0].Code;
  if (code === 404) {
    // Adding custom field to make it easier to detect 404s
    err._notFound = true;
  } else if (code === 403) {
    // Adding custom field to make it easier to detect 403s
    err._forbidden = true;
  } else if (code === 461) {
    // Adding custom field to make it easier to detect 461s
    err._dupe = true;
  } else if (code === 462) {
    // Adding custom field to make it easier to detect 462s
    err._inUse = true;
  }
};

const checkAccessRights = errors => {
  const accessRightsErrMsg = "Access denied - Missing user access rights";
  errors.forEach(error => {
    if (error.Details === accessRightsErrMsg && error.Code === 403) {
      alert(i18n.t("503permissionInfo"));
      window.location.href = "/";
    }
  });
};

const handleNoResponse = errorObj => {
  if (!navigator.onLine) {
    alert(i18n.t("noInternetConnection"));
  } else if (errorObj && !errorObj.response) {
    alert(errorObj.toString());
  } else {
    alert(i18n.t("unknownError"));
  }
};

const parseOutErrorMsg = errorObj => {
  const r = errorObj.response;
  let err = i18n.t("anErrorOccurred") + ".\r\n";
  if (r.data && r.data.Errors.length) {
    r.data.Errors.forEach(error => {
      err += error.Code + " " + error.Title + "\r\n";
    });
  } else {
    err = r.statusText;
  }
  err += "\r\n" + i18n.t("contactSupportForHelp");
  return err;
};

export default api;
