const da = {
    badge: 'Emblem',
    close: 'Luk',
    dataIterator: {
        noResultsText: 'Ingen matchende data fundet',
        loadingText: 'Indhenter data...',
    },
    dataTable: {
        itemsPerPageText: 'Rækker pr. side:',
        ariaLabel: {
            sortDescending: 'Sorteret faldende.',
            sortAscending: 'Sorteret stigende.',
            sortNone: 'Ikke sorteret.',
            activateNone: 'Aktiver for at fjerne sortering.',
            activateDescending: 'Aktiver for at sortere faldende.',
            activateAscending: 'Aktiver for at sortere stigende.',
        },
        sortBy: 'Sorter efter',
    },
    dataFooter: {
        itemsPerPageText: 'Rækker pr. side:',
        itemsPerPageAll: 'Alle',
        nextPage: 'Næste side',
        prevPage: 'Forrige side',
        firstPage: 'Første side',
        lastPage: 'Sidste side',
        pageText: '{0}-{1} af {2}',
    },
    datePicker: {
        itemsSelected: '{0} valgt',
        nextMonthAriaLabel: 'Næste måned',
        nextYearAriaLabel: 'Næste år',
        prevMonthAriaLabel: 'Forrige måned',
        prevYearAriaLabel: 'Forrige år',
    },
    noDataText: 'Ingen data tilgængelig',
    carousel: {
        prev: 'Forrige visuelle',
        next: 'Næste visuelle',
        ariaLabel: {
            delimiter: 'Karrusel dias {0} af {1}',
        },
    },
    calendar: {
        moreEvents: '{0} mere',
    },
    fileInput: {
        counter: '{0} filer',
        counterSize: '{0} filer ({1} total)',
    },
    timePicker: {
        am: 'AM',
        pm: 'PM',
    },
    pagination: {
        ariaLabel: {

            wrapper: 'Pagineringsnavigation',
            next: 'Neste side',
            previous: 'Forrige side',
            page: 'Gå til side {0}',
            currentPage: 'Nuværende side, Side {0}'
        }
    },
    rating: {
        ariaLabel: {
            item: 'Bedømmelse {0} af {1}',
        },
    },
}
export default da;