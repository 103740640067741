import Vue from "vue";
import Vuetify from "vuetify/lib";
import config from "../config/config";
import en from "vuetify/es5/locale/en";
import no from "vuetify/es5/locale/no";
import sv from "vuetify/es5/locale/sv";
import da from "../locales/custom-vuetify/da";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { en, no, da, sv},
    current: config.getVuetifyLocale()
  }
});
