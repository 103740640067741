import api from "./api";
import AlertTemplate from "@/util/AlertTemplate";
import user from "@/util/user";

const apiAlertTemplates = {
  getAll() {
    const selDep = user.getSelectedDepartment();
    const params = selDep ? { Avdeling: selDep } : null;
    return new Promise(resolve => {
      api.execute("HentMaler", params, "Maler").then(templates => {
        // Only return new JSON-format templates
        const parsedTemplates = templates
          .filter(template => {
            return template.Melding.substr(0, 1) === "{";
          })
          .map(template => {
            const alertTempl = new AlertTemplate();
            alertTempl.setFromApiObject(template);
            return alertTempl.get();
          });
        resolve(parsedTemplates);
      });
    });
  },

  create(template, department) {
    const params = {
      Mal: {
        Navn: template.name,
        Melding: createTemplateJson(template),
        Avdeling: department
      }
    };
    return api.execute("NyMal", params);
  },

  delete(template) {
    const params = { MalId: template.id };
    return api.execute("SlettMal", params);
  },

  update(template, department) {
    const params = {
      Mal: {
        MalId: template.id,
        Navn: template.name,
        Melding: createTemplateJson(template),
        Avdeling: department
      }
    };
    return api.execute("EndreMal", params);
  }
};

const createTemplateJson = template => {
  const obj = {
    alertName: template.alertName,
    text: template.text,
    emailTitle: template.emailTitle,
    voiceSettings: template.voiceSettings,
    responseOptions: template.responseOptions
  };
  return JSON.stringify(obj);
};

export default apiAlertTemplates;
