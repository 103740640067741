import store from "@/store";
import config from "@/config/config";
import Vue from "vue";

// It would be nice to use the i18n files for the supportedVoiceLanguages
// object, but an webpack error is thrown when the line below is used.
// I've not been able to find out why, so these language lines
// must be hard coded here. Erik
//import i18n from "@/i18n";

const configVoice = {
  defaultSetting: {
    attempts: 0,
    delay: 5,
    deputy: false,
    // The API also supports "active", but we have agreed to simplify this
    // in GroupAlert to strictly use "confirmed". Meaning that the user
    // must confirm a voice call with a key press to avoid repeated calls
    // (if set) and calling to deputy (if set).
    endOnState: "confirmed",
    responseOptions: [
      {
        number: 1,
        text: ""
      },
      {
        number: 2,
        text: ""
      },
      {
        number: 3,
        text: ""
      },
      {
        number: 4,
        text: ""
      },
      {
        number: 5,
        text: ""
      },
      {
        number: 6,
        text: ""
      },
      {
        number: 7,
        text: ""
      },
      {
        number: 8,
        text: ""
      }
    ]
  },
  getDefaultCallerId() {
    return config.getCallerId();
  },
  getDefaultProps() {
    return new Promise(resolve => {
      store.dispatch("updateUserProfile").then(() => {
        store.dispatch("updateVoices").then(voices => {
          const defaultCountryCode = store.getters.getDefaultCountryPhoneCode;
          const langCode = configVoice.getLangCodeFrom(defaultCountryCode);
          const defaultVoice = voices.find(voice => {
            return voice.LanguageCode === langCode;
          });
          resolve(defaultVoice);
        });
      });
    });
  },
  // "code" must match LanguageCode in API GetVoices return data.
  supportedVoiceLanguages: [
    {
      code: "nb-NO",
      countryCode: "+47",
      responseOptText: {
        press: "Tast",
        for: "for",
        repeat: "Tast 9 for repetisjon",
        responseConfirm: "Takk, ditt svar er registrert",
        noUserInputMsg: "Ingen svar registrert. Farvel"
      }
    },
    {
      code: "sv-SE",
      countryCode: "+46",
      responseOptText: {
        press: "Tryck",
        for: "för",
        repeat: "Tryck på 9 för upprepning",
        responseConfirm: "Tack, ditt svar är registrerat",
        noUserInputMsg: "Inga svar registrerade. Adjö"
      }
    },
    {
      code: "da-DK",
      countryCode: "+45",
      responseOptText: {
        press: "Tast",
        for: "for",
        repeat: "tast 9 for at høre meddelelsen" + " igen",
        responseConfirm: "Tak, dit svar er registreret",
        noUserImputMsg: "Intet svar er registreret, kaldet afbrydes"
      }
    },
    {
      code: "fi-FI",
      countryCode: "+358",
      responseOptText: {
        press: "Paina",
        for: "vastataksesi",
        repeat: "Paina 9 kuullaksesi viestin uudelleen",
        responseConfirm: "Kiitos, vastauksesi on rekisteröity",
        noUserInputMsg: "Emme saaneet vastausta. Hyvästi."
      }
    },
    {
      code: "en-US",
      countryCode: "+1",
      get responseOptText() {
        return configVoice.englishVoiceOptText;
      }
    },
    {
      code: "en-GB",
      countryCode: "+44",
      get responseOptText() {
        return configVoice.englishVoiceOptText;
      }
    }
  ],
  englishVoiceOptText: {
    press: "Press",
    for: "for",
    repeat: "Press 9 to hear again",
    responseConfirm: "Thank you, your response is registered",
    noUserInputMsg: "No response registered. Good bye"
  },
  getLangCodeFrom(countryCode) {
    const voiceLangCode = this.supportedVoiceLanguages.find(voiceLang => {
      return voiceLang.countryCode === countryCode;
    });
    return voiceLangCode ? voiceLangCode.code : "en-GB";
  }
};

export default configVoice;
