const eventLogConfig = {
  // Event log operation types.
  // They are defined here for easy overview for all event log types.
  operation: {
    importReceiversFromAlertPage: "ImportReceiversFromAlertPage",
    extraReceiverUsed: "ExtraReceiverUsed",
    receiversAddedFromDsf: "ReceiversAddedFromDsf",
    voiceFileUsedInAlert: "VoiceFileUsedInAlert",
    voiceFileRecordedOnAlertPage: "VoiceFileRecordedOnAlertPage",
    voiceFileRecorded: "VoiceFileRecorded",
    voiceFileUploaded: "VoiceFileUploaded",
    voiceFileEdited: "VoiceFileEdited",
    voiceUsedInAlert: "VoiceUsedInAlert",
    emailFileUploaded: "EmailFileUploaded",
    emailFileEdited: "EmailFileEdited",
    dsfExctraction: "DsfExctraction",
    scenarioUsed: "ScenarioUsed",
    themeChange: "ThemeChange",
    ageFilter: "AgeFilter"
  }
};

export default eventLogConfig;
