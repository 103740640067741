import api from "./api";
import config from "@/config/config";

const apiSession = {
  login: (user, pass, silent) => {
    let data = {
      UserId: user,
      Password: pass,
      SmsSender: config.appName
    };
    return api.execute("login", data, null, false, silent);
  },

  loginPin(user, pass, pin) {
    const params = {
      UserId: user,
      Password: pass,
      PinCode: pin
    };
    return api.execute("VerifyPincode", params, null);
  },

  exchangeTicketForSessionId(ticket) {
    const params = { Ticket: ticket };
    return api.execute("ExchangeTicketForSessionId", params, "SessionId");
  },

  setSessionDuration(sessionId, duration) {
    const params = {
      SessionId: sessionId,
      TimeToLive: duration
    };
    return api.execute("SetSession", params);
  },

  logout(sessionId) {
    return api.execute("logout", { SessionId: sessionId }, null, false);
  }
};

export default apiSession;
