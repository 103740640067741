const numberUtil = {
  periodToComma(input) {
    let output = input.toString();
    if (output.indexOf(".") !== -1) {
      output = output.split(".");
      if (output.length > 2) {
        throw output + " is not a valid number";
      }
      output = output[0] + "," + output[1];
    }
    return output;
  },

  addThousandSperators(input, delimiter) {
    // https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
    return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);
  },

  isNumber(n) {
    // https://stackoverflow.com/a/16655847/529171
    return !isNaN(parseFloat(n)) && isFinite(n);
  },

  bytesToMegabytes(bytes, numOfDecimals) {
    return bytes / 1024 / 1024;
  }
};

export default numberUtil;
