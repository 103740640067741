<template>
  <div class="text-center" v-if="show">
    <div>
      {{ text }}
    </div>
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </div>
</template>
<script>
export default {
  name: "LoadingSpinner",
  props: {
    text: {
      default: ""
    },
    smartDelay: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    show: false
  }),
  created() {
    if (this.smartDelay) {
      // A little delay before showing spinner. No need to show this if load time is < 500 ms
      setTimeout(() => {
        this.show = true;
      }, 500);
    } else {
      this.show = true;
    }
  }
};
</script>
<style scoped>
  .text-center {
    margin-top: 10px;
  }
</style>