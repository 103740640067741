/*
  This module is a wrapper for the department API. It caches the departments for a short period
  of time to avoid many unnecessary API calls when the app loads, since several components asks for the
  departments.
 */
import apiDepartment from "@/api/v24/api.department";

const department = {
  cacheLimit: 500,
  lastUpdate: null,
  cachedDepartments: [],

  getAll() {
    return new Promise(resolve => {
      if (this.lastUpdate && this.cachedDepartments.length) {
        const now = new Date();
        const diff = now - this.lastUpdate;
        if (diff < this.cacheLimit) {
          resolve(this.cachedDepartments);
          return;
        }
      }
      apiDepartment.getAll().then(departments => {
        this.cachedDepartments = departments;
        this.lastUpdate = new Date();
        resolve(departments);
      });
    });
  },

  customerUsesDepartments() {
    return new Promise(resolve => {
      this.getAll().then(allDepartments => {
        resolve(allDepartments.length > 1);
      });
    });
  }
};

export default department;
